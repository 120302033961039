import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './theme/public.css'
import './theme/theme.css'
import './util/js/jquery-1.8.3.min.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import layer from 'layui-layer'
Vue.use(layer)

import filter from './util/filter.js'
Vue.use(filter)

// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV == 'development') {
	Vue.config.devtools = true
} else {
	Vue.config.devtools = false
}
Vue.config.devtools = true
Vue.prototype.$UserID = window.localStorage.getItem('DHuserID') //用户id
Vue.prototype.$userType = window.localStorage.getItem('DHuserType') //登录用户类型,普通,机构
Vue.prototype.$ServiceType = window.localStorage.getItem('DHServiceType') //登录用户类型,0普通,1vip

/* ----------------------------------分类---------------------------------- */
Vue.prototype.$ZJJGId = '00ef7049-0bf2-40a7-9f31-daf8a23af7e1' //专家机构
Vue.prototype.$CBJGId = '50de965b-9ea4-45c0-80a4-258cc392331f' //出版机构库
Vue.prototype.$YJJGId = 'f724ae31-229e-4150-82d2-ea795d4d31b6' //研究机构库
Vue.prototype.$ZJXZId = '905c276f-8cf5-40c6-9a69-c8ae2776e5c' //专家学者库
Vue.prototype.$YZId = 'db227ae4-7586-4210-b110-d6003e5a10e0' //遗珍
Vue.prototype.$KnowlegeArtId = '6db4f991-4026-4198-87e4-2dbd37857dc1' //知识服务艺术鉴赏
Vue.prototype.$KnowlegeRelicId = '0d4de599-4de1-47c3-9d11-4eef3455d5ad' //知识服务考古文物
Vue.prototype.$KnowlegeLearnId = '54a17c55-8f4a-419e-9e10-cd11afd12195' //知识服务学术探讨
Vue.prototype.$KnowlegeFigureId = '29f5e2f7-3b21-4457-8174-c52eeb1d92d8' //知识服务人物传记
Vue.prototype.$CatalogAdditionalId = '11cb9584-7606-4be2-80a7-babebd47b886' //索引目录其他
Vue.prototype.$CatalogFigureId = '704d92c6-b46d-4042-8633-8e002eb625fe' //索引目录人物
Vue.prototype.$CatalogSitesId = '762a6f81-7412-4442-bc53-ede72456db93' //索引目录遗址
Vue.prototype.$CatalogBibliographyId = '8573a8a8-7b93-484d-b221-f9e17b51a499' //索引目录论著
Vue.prototype.$CatalogRelicId = 'b0ffd0bb-094d-4f79-be04-469ae9f7ab82' //索引目录文物
Vue.prototype.$CatalogLiteratureId = 'f34d47e1-1d0c-4249-8486-3e8fab1733cc' //索引目录文献
Vue.prototype.$CatalogGrottoId = 'b1e840ef-0353-4745-a0ed-d560b48c4951' //索引目录石窟
Vue.prototype.$LegacyModelId = '4831D3BF-5B2E-42E3-87BA-8DA8972601B0' //遗珍彩塑
Vue.prototype.$LegacyImgId = 'D910D866-DA80-42E0-9D1E-F8D2BEDFDF8E' //遗珍壁画
Vue.prototype.$LegacyRelicId = '401233F0-8261-43E8-9AB7-06CCB0E17479' //遗珍出土文物
Vue.prototype.$LegacyGrottoId = 'BB438F54-B51E-447C-9B9B-DC0D310BF348' //遗珍石窟
Vue.prototype.$LegacySitesId = '3574C94E-37A3-4F50-9020-6C78C714D4C4' //遗珍遗址
Vue.prototype.$LegacyLiteratureId = '604C4CDE-4E8E-4A0D-8FD6-74943763B471' //遗珍文献
Vue.prototype.$LegacyFigureId = '27C95F2B-23EB-42FB-B071-F151990C7695' //遗珍人物
Vue.prototype.$LegacyArtId = '6027E934-4F80-4317-929E-DFB81DBB753C' //遗珍艺术品
Vue.prototype.$LegacyPublishId = 'C405C815-1FBA-4C51-B127-27BFAE3CD861' //遗珍出版物
Vue.prototype.$LegacyAdditionalId = '226E4EF0-96EE-444D-894C-095E56FC3053' //遗珍其他
Vue.prototype.$ArticelClassId = '75bf25ee-1326-4dc4-97cc-c5db0efa122b' //知识服务
Vue.prototype.$SearchAchievtBookId = '73992245-7951-4DA6-9C20-F60759E1F251' //研究成果图书
Vue.prototype.$SearchAchievtEntryId = 'fe374966-e866-4958-a378-c7090960a0e2' //研究成果论文
Vue.prototype.$SearchAchievtAdditionalId =
	'0bf8b55a-0ac5-41ed-8513-acdf0d3372db' //研究成果其他

/* ----------------------------------地址配置---------------------------------- */
// Vue.prototype.$ImgUrl = 'http://192.168.1.115:8080/' //图片路径
// Vue.prototype.$WebUrl = 'http://192.168.1.115:8081/#/' //链接地址
// Vue.prototype.$ImgUrl = 'http://192.168.1.101:9113/' //测试图片路径
Vue.prototype.$WebUrl = 'https://dh.gseph.xyz/#/'

// Vue.prototype.$WebUrl = 'http://192.168.1.101:9113/#/' //测试链接地址

Vue.prototype.$ImgUrl = 'https://dh.gseph.xyz/' //图片路径
// Vue.prototype.$WebUrl = 'https://dh.gseph.xyz/#/'

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	next()
})
