/* eslint-disable */
import axios from '../util/axios'
import config from '../config/index'

export default class CheckService {
	// 获取验证码
	static SendPhoneValidCode(Tel) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/SendPhoneValidCode',
			data: { Tel },
		})
	}
	// 用户注册
	static UserRegister(Tel, Pwd, UserType, Code) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserRegister',
			data: { Tel, Pwd, UserType, Code },
		})
	}
	// 用户登录
	static UserLogin(Account, Pwd, Ip) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserLogin',
			data: { Account, Pwd, Ip },
		})
	}
	// 通过Ip登录
	static UserOrgIpLogin(ip) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserOrgIpLogin',
			data: { ip },
		})
	}
	// 获取新闻列表
	static GetNewsList(pageIndex, pageSize) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetNewsList',
			data: { pageIndex, pageSize },
		})
	}
	// 获取新闻详情
	static GetNewsDetail(newsID, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetNewsInfo',
			data: { newsID, userId },
		})
	}
	// 获取热门新闻
	static GetNewsHotList(TopNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetHotNewsList',
			data: { TopNumber },
		})
	}
	// 获取推荐新闻
	static GetRecommendNewsList(TopNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetRecommendNewsList',
			data: { TopNumber },
		})
	}
	// 获取推荐动态
	static GetRecommendAcademicDynamicList(TopNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetRecommendAcademicDynamicList',
			data: { TopNumber },
		})
	}
	// 获取个人用户信息
	static GetUserPerInfo(Userid, Tel) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserPerInfo',
			data: { Userid, Tel },
		})
	}
	// 获取机构用户信息
	static GetUserOrgInfo(Userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserOrgInfo',
			data: { Userid },
		})
	}
	// 修改个人信息
	static UserPerEdit(Userid, UserName, Sex, Birthday, Avatar) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserPerEdit',
			data: { Userid, UserName, Sex, Birthday, Avatar },
		})
	}
	// 获取学界动态
	static GetAcademicDynamicList(pageIndex, pageSize) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetAcademicDynamicList',
			data: { pageIndex, pageSize },
		})
	}
	// 获取热门学界动态
	static GetHotAcademicDynamicList(TopNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetHotAcademicDynamicList',
			data: { TopNumber },
		})
	}
	// 获取新闻附件
	static GetNewsFile(newsID) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/News/GetNewsFile',
			data: { newsID },
		})
	}
	// 修改密码
	static EditUserPassword(userId, Pwd, PwdNew) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserCipher',
			data: { userId, Pwd, PwdNew },
		})
	}
	// 获取验证码
	static SendValidCode(userId, account, validModule, validType) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/SendValidCode',
			data: { userId, account, validModule, validType },
		})
	}
	//验证验证码
	static ValidCode(userId, account, validModule, validType, validCode) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/ValidCode',
			data: { userId, account, validModule, validType, validCode },
		})
	}
	//提交修改手机/邮箱POST
	static UpdateAccount(userId, account, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UpdateAccount',
			data: { userId, account, type },
		})
	}
	// 获取在线投稿标签库POST
	static GetKeywords(keyword) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/GetKeywords',
			data: { keyword },
		})
	}
	// 添加稿件
	static AddManuscript(
		title,
		keywordIds,
		keywords,
		userId,
		userName,
		tel,
		sex,
		birthday,
		address
	) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/AddManuscript',
			data: {
				title,
				keywordIds,
				keywords,
				userId,
				userName,
				tel,
				sex,
				birthday,
				address,
			},
		})
	}
	// 编辑稿件
	static EditManuscript(
		id,
		title,
		keywordIds,
		keywords,
		summary,
		theme,
		abstract,
		resPath,
		resName,
		userId,
		userName,
		tel,
		sex,
		birthday,
		education,
		work,
		research,
		introduction,
		address
	) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/EditManuscript',
			data: {
				id,
				title,
				keywordIds,
				keywords,
				summary,
				theme,
				abstract,
				resPath,
				resName,
				userId,
				userName,
				tel,
				sex,
				birthday,
				education,
				work,
				research,
				introduction,
				address,
			},
		})
	}
	// 获取稿件详情
	static GetManuscriptInfo(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/GetManuscriptInfo',
			data: { id },
		})
	}
	//获取我的投稿
	static GetManuscriptList(pageIndex, pageSize, state, keyword, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetManuscriptList',
			data: { pageIndex, pageSize, state, keyword, userId },
		})
	}
	// 获取在线投稿相关知识元
	static GetManuElementList(keywordIds, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/GetRelationElementList',
			data: { keywordIds, topNumber },
		})
	}
	// 获取在线投稿相关知识元
	static GetManuEntryList(keywordIds, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/GetRelationEntryList',
			data: { keywordIds, topNumber },
		})
	}
	// 删除在线投稿
	static DeleteManuscript(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/DeleteManuscript',
			data: { id },
		})
	}
	// 上传文件
	static FileUpload(forderPrefix, files) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Manuscript/FileUpload',
			data: { forderPrefix, files },
		})
	}
	// 获取消息管理
	static GetMessageList(pageIndex, pageSize, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetMessageList',
			data: { pageIndex, pageSize, userId },
		})
	}
	// 删除消息管理
	static DeleteMessage(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Message/DeleteMessage',
			data: { id },
		})
	}
	// 删除消息管理
	static UpdateReadStatus(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Message/UpdateReadStatus',
			data: { id },
		})
	}
	//上传头像
	static UserAvator(ImageBuffer, Forderprefix) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserAvator',
			data: { ImageBuffer, Forderprefix },
		})
	}
	//获取机构用户信息
	static GetUserOrgInfo(Userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserOrgInfo',
			data: { Userid },
		})
	}
	//编辑机构信息
	static UserOrgEdit(
		Userid,
		UserName,
		Contacts,
		OrgTel,
		Address,
		Avatar,
		Introduction
	) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UserOrgEdit',
			data: {
				Userid,
				UserName,
				Contacts,
				OrgTel,
				Address,
				Avatar,
				Introduction,
			},
		})
	}
	// 获取banner
	static GetBanner(type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetBanner',
			data: { type },
		})
	}
	// 获取每周一书
	static GetBookOfWeek() {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetBookOfWeek',
		})
	}
	// 获取当月期刊
	static GetJournalOfMonth() {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetJournalOfMonth',
		})
	}
	// 获取分类下词条、条目、图片、图书
	static GetIndexClassResList(classId, topNumber, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetClassResList',
			data: { classId, topNumber, type },
		})
	}
	// 获取古文献列表
	static GetAnLiteratureList(pageIndex, pageSize, searchWord) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetAnLiteratureList',
			data: { pageIndex, pageSize, searchWord },
		})
	}
	// 获取首页图书、期刊
	static GetResourceList(topNumber, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetResourceList',
			data: { topNumber, type },
		})
	}
	// 获取首页研究成果论文、其他
	static GetEntryList(topNumber, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetEntryList',
			// url: '/api/Index/GetClassResList',

			data: {
				topNumber,
				classId: 'C069E208-9834-4F30-8314-E9D90FB560CB',
			},
		})
	}
	//获取分类
	static GetClassTree() {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetClassTree',
		})
	}
	//获取列表
	static GetResList(pageIndex, pageSize, searchWord, classId, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetResList',
			data: { pageIndex, pageSize, searchWord, classId, type },
		})
	}
	// 获取热门资源
	static GetHotResList(type, topNumber, classId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetHotResList',
			data: { type, topNumber, classId },
		})
	}
	// 获取图书详情
	static GetBookModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Book/GetBookModel',
			data: { id, userid },
		})
	}
	//获取相关词条
	static GetRelationElementList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationElementList',
			data: { type, id, topNumber },
		})
	}
	// 获取相关条目
	static GetRelationEntryList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationEntryList',
			data: { type, id, topNumber },
		})
	}
	// 获取相关图书
	static GetRelationBookList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationBookList',
			data: { type, id, topNumber },
		})
	}
	// 获取相关图片
	static GetRelationImageList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationImageList',
			data: { type, id, topNumber },
		})
	}
	// 获取相关
	static GetResourceRelationList(id, type, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationList',
			data: { id, type, topNumber },
		})
	}
	// 获取相关音频
	static GetRelationAudioList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationAudioList',
			data: { type, id, topNumber },
		})
	}
	// 获取相关视频
	static GetRelationVideoList(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationVideoList',
			data: { type, id, topNumber },
		})
	}
	// 添加收藏
	static InsertUserColletion(resid, resname, userid, restype) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/InsertUserColletion',
			data: { resid, resname, userid, restype },
		})
	}
	// 取消收藏POST
	static UpdateUserColletion(userid, resid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/UpdateUserColletion',
			data: { userid, resid },
		})
	}
	// 获取无分类资源列表
	static GetResListNoClass(pageIndex, pageSize, searchWord, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetResListNoClass',
			data: { pageIndex, pageSize, searchWord, type },
		})
	}
	//获取期刊详情
	static GetJournalModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Journal/GetJournalModel',
			data: { id, userid },
		})
	}
	//获取精选期刊
	static GetJournalList(pageIndex, pageSize, id, name) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Journal/GetJournalList',
			data: { pageIndex, pageSize, id, name },
		})
	}
	//获取相关期刊
	static GetRelationJournal(id, number) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Journal/GetRelationJournal',
			data: { id, number },
		})
	}
	// 获取条目详情
	static GetEntryModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Entry/GetEntryModel',
			data: { id, userid },
		})
	}
	// 获取图片详情
	static GetImageModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Image/GetImageModel',
			data: { id, userid },
		})
	}
	// 获取词条详情
	static GetElementModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Element/GetElementModel',
			data: { id, userid },
		})
	}
	// 获取词条释义
	static GetElementAnnotationList(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Element/GetElementAnnotationList',
			data: { id },
		})
	}
	// 获取对象属性
	static GetElementElementObjproList(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Element/GetElementElementObjproList',
			data: { id },
		})
	}
	// 获取数据属性POST
	static GetElementElementDataproList(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Element/GetElementElementDataproList',
			data: { id },
		})
	}
	// 获取知识图谱
	static GetElementAtlas(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Element/GetElementAtlas',
			data: { id },
		})
	}
	// 获取条目推荐图书
	static GetRecommendBook(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Entry/GetRecommendBook',
			data: { id },
		})
	}
	// 获取epub阅读文件
	static GetEpubToc(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Book/GetEpubToc',
			data: { id },
		})
	}
	// 获取我的收藏词条
	static GetUserColletionElementList(pageIndex, pageSize, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetUserColletionElementList',
			data: { pageIndex, pageSize, userid },
		})
	}
	// 获取我的收藏条目
	static GetUserColletionEntryList(pageIndex, pageSize, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetUserColletionEntryList',
			data: { pageIndex, pageSize, userid },
		})
	}
	// 获取我的收藏图书
	static GetUserColletionBookList(pageIndex, pageSize, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetUserColletionBookList',
			data: { pageIndex, pageSize, userid },
		})
	}
	// 获取我的收藏图片
	static GetUserColletionImageList(pageIndex, pageSize, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetUserColletionImageList',
			data: { pageIndex, pageSize, userid },
		})
	}
	// 获取我的收藏期刊
	static GetUserColletionJournalList(pageIndex, pageSize, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/GetUserColletionJournalList',
			data: { pageIndex, pageSize, userid },
		})
	}
	// 获取期刊epub阅读POST
	static GetJournalEpubToc(id) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Journal/GetEpubToc',
			data: { id },
		})
	}
	// 获取搜索词
	static GetSearchWords(topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetSearchWords',
			data: { topNumber },
		})
	}
	// 获取热门机构
	static GetTopMechanismList(meType, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetTopMechanismList',
			data: { meType, topNumber },
		})
	}
	// 获取热门专家
	static GetTopExpertList(topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetTopExpertList',
			data: { topNumber },
		})
	}
	// 获取机构列表
	static GetMechanismList(pageIndex, pageSize, meType, pyIndex) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetMechanismList',
			data: { pageIndex, pageSize, meType, pyIndex },
		})
	}
	// 获取专家列表
	static GetExpertList(pageIndex, pageSize, pyIndex) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetExpertList',
			data: { pageIndex, pageSize, pyIndex },
		})
	}
	// 获取机构信息
	static GetMechanismInfo(id, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetMechanismInfo',
			data: { id },
		})
	}
	// 获取专家信息
	static GetExpertInfo(id, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetExpertInfo',
			data: { id },
		})
	}
	// 获取音视频信息
	static GetMediaModel(id, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Media/GetMediaModel',
			data: { id, userid },
		})
	}
	// 检索
	static GetEsSearch(
		pageIndex,
		pageSize,
		resTypes,
		SearchWord,
		SearchWords,
		startInterval,
		endInterval
	) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/GetEsSearch',
			data: {
				pageIndex,
				pageSize,
				resTypes,
				SearchWord,
				SearchWords,
				startInterval,
				endInterval,
			},
		})
	}
	// 获取专家条目
	static GetLibraryRelationList(topNumber, id, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Library/GetRelationList',
			data: { topNumber, id, type },
		})
	}
	// 注册上线状态
	static RegisterUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/RegisterUserOnline',
			data: { userId, token },
		})
	}
	//退出上线状态
	static LogoutUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/LogoutUserOnline',
			data: { userId, token },
		})
	}
	// 检查上线状态
	static CheckUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/CheckUserOnline',
			data: { userId, token },
		})
	}
	// 检查机构用户是否还在线
	static CheckMechanUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/CheckMechanUserOnline',
			data: { userId, token },
		})
	}
	// 注册机构用户在线状态
	static LogoutMechanicalUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/LogoutMechanicalUserOnline',
			data: { userId, token },
		})
	}
	// 机构用户退出在线状态
	static LogoutMechanUserOnline(userId, token) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/User/LogoutMechanUserOnline',
			data: { userId, token },
		})
	}
	// 获取推荐词条，文章，图片，图书，其他
	static GetClassRecommendResList(classId, topNumber, type) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetClassRecommendResList',
			data: { classId, topNumber, type },
		})
	}
	// 添加古文献浏览量
	static AddAnLiteratureBrowser(id, name, userId) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/AddAnLiteratureBrowser',
			data: { id, name, userId },
		})
	}
	// 添加搜索记录
	static SearchRecord(keyWords, userid) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Search/SearchRecord',
			data: { keyWords, userid },
		})
	}
	// 获取条目相关人物
	static GetRelationEntryPerson(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationEntryPerson',
			data: { type, id, topNumber },
		})
	}
	// 获取条目的相关条目
	static GetEntryRelationEntry(type, id, topNumber) {
		return axios.post({
			baseURL: config.baseURL,
			url: '/api/Index/GetRelationEntry',
			data: { type, id, topNumber },
		})
	}
}
