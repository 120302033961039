/* eslint-disable */
'use strict'
import axios from 'axios'
// import qs from 'qs'
import config from '../config/index'

axios.interceptors.request.use(
	(config) => {
		// loading
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

axios.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		return Promise.resolve(error.response)
	}
)

function checkStatus(response) {
	// loading
	// 如果http状态码正常，则直接返回数据
	if (
		response &&
		(response.status === 200 ||
			response.status === 304 ||
			response.status === 400)
	) {
		// return JSON.parse(response.data)
		// 如果不需要除了data之外的数据，可以直接 return response.data
		return response.data
	} else {
		console.log('响应异常', response)
	}
	// 异常状态下，把错误信息返回去
	return {
		status: -404,
		msg: '网络异常',
	}
}

// function checkCode (res) {
//     // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
//     if (res.status === 404) {
//         Notify(res.msg)
//     }else {
//         console.log(111)
//         Notify(res)
//     }
//     return res
// }

export default {
	post(opts) {
		if (opts.baseURL == 'https://dh.gseph.xyz/es/search') {
			// if (opts.baseURL == "http://dunhuang.gseph.xyz:81/es/search") {
			console.log(opts)
			return axios({
				method: 'post',
				baseURL: opts.baseURL,
				url: opts.url,
				params: opts.data, // get 请求时带的参数
				timeout: 10000,
			}).then((response) => {
				console.log(response)
				return response
			})
		} else {
			return axios({
				method: 'post',
				baseURL: config.baseUrl,
				url: opts.url,
				data: opts.data, // post 请求时带的参数
				timeout: 10000,
			}).then((response) => {
				return checkStatus(response)
			})
		}
	},
	get(opts) {
		return axios({
			method: 'get',
			baseURL: config.baseUrl,
			url: opts.url,
			params: opts.data, // get 请求时带的参数
			headers: {
				dataType: 'json',
				contentType: 'application/json',
			},
		}).then((response) => {
			return checkStatus(response)
		})
	},
}
