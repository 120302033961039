<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
/* eslint-disable */
import Vue from "vue";
import CheckInService from "./api/CheckInService.js";
import MD5 from "js-md5";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      defaultImg1: 'this.src="' + require("./images/picture1.jpg") + '"', //默认图片
      defaultImg2: 'this.src="' + require("./images/picture2.jpg") + '"', //默认图片
    };
  },
  created() {
    // 检查用户是否处于登录状态
    if (this.$UserID && this.$userType == 0) {
      // 检查个人用户
      CheckInService.CheckUserOnline(
        this.$UserID,
        window.localStorage.getItem("DHToken")
      ).then((res) => {
        console.log("个人用户登录状态：", res);
        if (res.Tag != 1) {
          // 不在线，进行自动登录
          console.log("个人用户执行了自动登录");
          this.autoLogin();
        }
      });
    } else if (this.$UserID && this.$userType == 1) {
      // 机构用户进行状态监测，一个账号可以同时登录多台设备
      CheckInService.CheckMechanUserOnline(
        this.$UserID,
        window.localStorage.getItem("DHToken")
      ).then((res) => {
        // 检查用户是否在线
        console.log("机构用户登录状态", res);
        if (res.Tag != 1) {
          console.log("机构用户执行了自动登录");
          this.autoLogin();
        }
      });
    }
  },
  mounted() {
    var that = this;
    setInterval(function() {
      // 每隔一分钟对用户的在线状态进行检测
      that.CheckUserOnline();
    }, 1000 * 30);

    if (!window.localStorage.getItem("DHuserID")) {
      //如果用户未登录通过Ip自动登录
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          const ip = data.ip;
          //通过ip地址获取所在地
          fetch(`https://api.vore.top/api/IPdata?ip=${ip}`).then((res) =>
            res.json().then((data) => {
              CheckInService.UserOrgIpLogin(data.ipinfo.text).then((res) => {
                window.localStorage.setItem("DHuserID", res.Result.ID);
                window.localStorage.setItem("DHuserType", res.Result.UserType);
                window.localStorage.setItem(
                  "DHServiceType",
                  res.Result.ServiceType
                );
                window.localStorage.setItem("DHToken", this.createToken());
                Vue.prototype.$UserID = res.Result.ID;
                Vue.prototype.$userType = res.Result.UserType;
                Vue.prototype.$ServiceType = res.Result.ServiceType;
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              });
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    // console.log(sessionStorage.getItem("AddressIP"));
  },
  methods: {
    autoLogin() {
      // 获取cookie中的用户名和密码，获取的到就自动登录，否则就清除vue原型中的用户id
      let phone = this.getCookie("user");
      let password = this.decrypt(this.getCookie("pswd"));
      if (phone && password) {
        // 记住了用户名和密码，进行自动登录
        CheckInService.UserLogin(
          phone.trim(),
          MD5(password.trim()),
          sessionStorage.getItem("AddressIP")
        ).then((res) => {
          if (res.Tag == 1) {
            // 注册用户在线状态
            if (res.Result.UserType == 1) {
              // 判断是否是机构用户
              CheckInService.LogoutMechanicalUserOnline(
                res.Result.UserType,
                window.localStorage.getItem("DHToken")
              ).then((resp) => {
                // 机构用户注册在线状态
                if (resp.Tag === 1) {
                  window.sessionStorage.setItem("orgUserOnline", true);
                }
              });
            } else {
              CheckInService.RegisterUserOnline(
                res.Result.ID,
                window.localStorage.getItem("DHToken")
              ).then((msg) => {
                // 普通用户注册在线状态
              });
            }

            // 目前后台对vip用户的类型改变不了，先通过vip到期时间判断是否是vip用户
            if (res.Result.ServiceDate) {
              let ServiceDate = res.Result.ServiceDate.replace(
                /-/g,
                "/"
              ).replace("T", " ");
              let vipTime = new Date(ServiceDate).getTime();
              if (vipTime > Date.now()) {
                // 会员没有过期，改变为会员类型
                window.localStorage.setItem("DHServiceType", 1);
                Vue.prototype.$ServiceType = 1;
              }
            }
          } else {
            // 自动登录失败，密码可能被修改，清除userId
            Vue.prototype.$UserID = "";
          }
        });
      } else {
        // Vue.prototype.$UserID = "";
      }
    },
    CheckUserOnline() {
      var that = this;
      if (that.$UserID && that.$userType == 0) {
        // 个人用户进行状态检测，一个账号同时只能登录一台设备
        CheckInService.CheckUserOnline(
          that.$UserID,
          window.localStorage.getItem("DHToken")
        ).then((res) => {
          if (res.Tag != 1) {
            let msg =
              "当前账号在另一地点登录， 您被迫下线。若非本人操作，您的登录密码很可能已经泄露，请及时修改密码。";
            that.userLogout(msg);
          }
        });
      } else if (
        that.$UserID &&
        that.$userType == 1 &&
        window.sessionStorage.getItem("orgUserOnline")
      ) {
        // 机构用户进行状态监测，一个账号可以同时登录多台设备
        CheckInService.CheckMechanUserOnline(
          that.$UserID,
          window.localStorage.getItem("DHToken")
        )
          .then((res) => {
            // 检查用户是否在线
            // console.log("检查用户是否在线", res);
            return res;
          })
          .then((res) => {
            if (res.Tag === 1) {
              // 用户在线
              CheckInService.LogoutMechanicalUserOnline(
                that.$UserID,
                window.localStorage.getItem("DHToken")
              ).then((resp) => {
                // 注册和更新机构用户在线状态
                // console.log("更新机构用户在线状态成功！");
              });
            } else {
              let msg =
                "当前账号登录人数已经超过最大登录人数限制，您被迫下线。请注意检查密码是否泄漏。";
              that.userLogout(msg);
            }
          });
      }
    },
    userLogout(msg) {
      // 强迫用户退出登录
      this.$alert(msg, "提示", {
        confirmButtonText: "确定",
      });
      window.localStorage.removeItem("DHuserID");
      window.localStorage.removeItem("DHuserType");
      window.localStorage.removeItem("DHServiceType");
      window.localStorage.removeItem("DHToken");
      Vue.prototype.$UserID = "";
      Vue.prototype.$userType = "";
      Vue.prototype.$ServiceType = "";
      this.$router.push({
        path: "/Login",
      });
    },
    getCookie(name) {
      // 获取cookie
      var reg = RegExp(name + "=([^;]+)");
      var arr = document.cookie.match(reg);
      if (arr) {
        return arr[1];
      } else {
        return "";
      }
    },
    decrypt(value) {
      // 用Base64解密
      let salt = "pwax";
      return Base64.decode(value).substr(salt.length);
    },

    createToken() {
      var code = "";
      var codeLength = 10; //验证码的长度
      var random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z"
      ); //随机数
      for (var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random() * 36);
        code += random[index];
      }
      return code;
    },
  },
};
</script>
