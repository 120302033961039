import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
      path: '/',
      name: 'Index',
      component: () => import('../views/Index/Index.vue')
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('../views/Login/Login.vue')
    },
    {
      path: '/ForgetPassword',
      name: 'ForgetPassword',
      component: () => import('../views/Login/ForgetPassword.vue')
    },
    {
      path: '/ForgetPasswordSec',
      name: 'ForgetPasswordSec',
      component: () => import('../views/Login/ForgetPasswordSec.vue')
    },
    {
      path: '/Register',
      name: 'Register',
      component: () => import('../views/Register/Register.vue')
    },
    {
      path: '/NewsList',
      name: 'NewsList',
      component: () => import('../views/News/NewsList/NewsList.vue')
    },
    {
      path: '/NewsDetail',
      name: 'NewsDetail',
      component: () => import('../views/News/NewsDetail/NewsDetail.vue')
    },
    {
      path: '/PerUserInfo',
      name: 'PerUserInfo',
      component: () => import('../views/UserCenter/PerUserInfo/PerUserInfo.vue')
    },
    {
      path: '/PerUserSafe',
      name: 'PerUserSafe',
      component: () => import('../views/UserCenter/PerUserSafe/PerUserSafe.vue')
    },
    {
      path: '/EditPerUser',
      name: 'EditPerUser',
      component: () => import('../views/UserCenter/EditPerUser/EditPerUser.vue')
    },
    {
      path: '/EditPerPassword',
      name: 'EditPerPassword',
      component: () => import('../views/UserCenter/PerUserSafe/EditPerPassword/EditPerPassword.vue')
    },
    {
      path: '/AcademicDynamicsList',
      name: 'AcademicDynamicsList',
      component: () => import('../views/AcademicDynamics/AcademicDynamicsList/AcademicDynamicsList.vue')
    },
    {
      path: '/EditPerEmail',
      name: 'EditPerEmail',
      component: () => import('../views/UserCenter/EditPerEmail/EditPerEmail.vue')
    },
    {
      path: '/EditSecPerEmail',
      name: 'EditSecPerEmail',
      component: () => import('../views/UserCenter/EditPerEmail/EditSecPerEmail/EditSecPerEmail.vue')
    },
    {
      path: '/EditPerPhone',
      name: 'EditPerPhone',
      component: () => import('../views/UserCenter/EditPerPhone/EditPerPhone.vue')
    },
    {
      path: '/EditSecPerPhone',
      name: 'EditSecPerPhone',
      component: () => import('../views/UserCenter/EditPerPhone/EditSecPerPhone/EditSecPerPhone.vue')
    },
    {
      path: '/ManuscriptEdit',
      name: 'ManuscriptEdit',
      component: () => import('../views/ManuscriptEdit/ManuscriptEdit/ManuscriptEdit.vue')
    },
    {
      path: '/ManuscriptEditOne',
      name: 'ManuscriptEditOne',
      component: () => import('../views/ManuscriptEdit/ManuscriptEditOne/ManuscriptEditOne.vue')
    },
    {
      path: '/UserManuscriptList',
      name: 'UserManuscriptList',
      component: () => import('../views/UserCenter/UserManuscriptList/UserManuscriptList.vue')
    },
    {
      path: '/ManuscriptDetail',
      name: 'ManuscriptDetail',
      component: () => import('../views/UserCenter/UserManuscriptList/ManuscriptDetail/ManuscriptDetail.vue')
    },
    {
      path: '/UserMessageList',
      name: 'UserMessageList',
      component: () => import('../views/UserCenter/UserMessageList/UserMessageList.vue')
    },
    {
      path: '/UserCollection',
      name: 'UserCollection',
      component: () => import('../views/UserCenter/UserCollection/UserCollection.vue')
    },
    {
      path: '/OrgUserInfo',
      name: 'OrgUserInfo',
      component: () => import('../views/UserCenter/OrgUserInfo/OrgUserInfo.vue')
    },
    {
      path: '/EditOrgUser',
      name: 'EditOrgUser',
      component: () => import('../views/UserCenter/EditOrgUser/EditOrgUser.vue')
    },
    {
      path: '/AnLiteratureList',
      name: 'AnLiteratureList',
      component: () => import('../views/AnLiteratureList/AnLiteratureList.vue')
    },
    {
      path: '/AnliteratureRead',
      name: 'AnliteratureRead',
      component: () => import('../views/AnLiteratureList/AnliteratureRead.vue')
    },
    {
      path: '/IndexCatalogIndex',
      name: 'IndexCatalogIndex',
      component: () => import('../views/IndexCatalogIndex/IndexCatalogIndex.vue')
    },
    {
      path: '/SearchAchievtIndex',
      name: 'SearchAchievtIndex',
      component: () => import('../views/SearchAchievtIndex/SearchAchievtIndex.vue')
    },
    {
      path: '/LegacyIndex',
      name: 'LegacyIndex',
      component: () => import('../views/LegacyIndex/LegacyIndex.vue')
    },
    {
      path: '/EntryIndex',
      name: 'EntryIndex',
      component: () => import('../views/EntryIndex/EntryIndex.vue')
    },
    {
      path: '/MainList',
      name: 'MainList',
      component: () => import('../views/MainList/MainList.vue')
    },
    {
      path: '/ElementList',
      name: 'ElementList',
      component: () => import('../views/MainList/ElementList/ElementList.vue')
    },
    {
      path: '/EntryList',
      name: 'EntryList',
      component: () => import('../views/MainList/EntryList/EntryList.vue')
    },
    {
      path: '/BookList',
      name: 'BookList',
      component: () => import('../views/MainList/BookList/BookList.vue')
    },
    {
      path: '/ImageList',
      name: 'ImageList',
      component: () => import('../views/MainList/ImageList/ImageList.vue')
    },
    {
      path: '/SearchAchievtEntryList',
      name: 'SearchAchievtEntryList',
      component: () => import('../views/MainList/SearchAchievtEntryList/SearchAchievtEntryList.vue')
    },
    {
      path: '/MediaList',
      name: 'MediaList',
      component: () => import('../views/MainList/MediaList/MediaList.vue')
    },
    {
      path: '/BookDetail',
      name: 'BookDetail',
      component: () => import('../views/Detail/BookDetail/BookDetail.vue')
    },
    {
      path: '/SearchAchievtList',
      name: 'SearchAchievtList',
      component: () => import('../views/SearchAchievtList/SearchAchievtList.vue')
    },
    {
      path: '/GeneralJournaDetail',
      name: 'GeneralJournaDetail',
      component: () => import('../views/Detail/GeneralJournaDetail/GeneralJournaDetail.vue')
    },
    {
      path: '/JournalManagerDetail',
      name: 'JournalManagerDetail',
      component: () => import('../views/Detail/JournalManagerDetail/JournalManagerDetail.vue')
    },
    {
      path: '/JournalReadEpub',
      name: 'JournalReadEpub',
      component: () => import('../views/Detail/JournalManagerDetail/JournalReadEpub.vue')
    },
    {
      path: '/JournalReadPDF',
      name: 'JournalReadPDF',
      component: () => import('../views/Detail/JournalManagerDetail/JournalReadPDF.vue')
    },
    {
      path: '/ElementDetail',
      name: 'ElementDetail',
      component: () => import('../views/Detail/ElementDetail/ElementDetail.vue')
    },
    {
      path: '/EntryDetail',
      name: 'EntryDetail',
      component: () => import('../views/Detail/EntryDetail/EntryDetail.vue')
    },
    {
      path: '/ImageDetail',
      name: 'ImageDetail',
      component: () => import('../views/Detail/ImageDetail/ImageDetail.vue')
    },
    {
      path: '/XMLReading',
      name: 'XMLReading',
      component: () => import('../views/Detail/EntryDetail/XMLReading.vue')
    },
    {
      path: '/ReadEpub',
      name: 'ReadEpub',
      component: () => import('../views/Detail/BookDetail/ReadEpub.vue')
    },
    {
      path: '/ReadPdf',
      name: 'ReadPdf',
      component: () => import('../views/Detail/BookDetail/ReadPdf.vue')
    },
    {
      path: '/MechanismList',
      name: 'MechanismList',
      component: () => import('../views/MechanismList/MechanismList.vue')
    },
    {
      path: '/MechanismDetail',
      name: 'MechanismDetail',
      component: () => import('../views/Detail/MechanismDetail/MechanismDetail.vue')
    },
    {
      path: '/UserPurchaseList',
      name: 'UserPurchaseList',
      component: () => import('../views/UserCenter/UserPurchaseList/UserPurchaseList.vue')
    },
    {
      path: '/SeniorSearch',
      name: 'SeniorSearch',
      component: () => import('../views/Search/SeniorSearch/SeniorSearch.vue')
    },
    {
      path: '/SearchResult',
      name: 'SearchResult',
      component: () => import('../views/Search/SearchResult/SearchResult.vue')
    },
	
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
